@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Raleway:wght@700&display=swap');

// colors - 264653, 2A9D8F, E9C46A, F4A261, E76F51
// colors2 - E63946, F1FAEE, A8DADC, 457B9D, 1D3557

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  background-color: white;
  overflow: hidden;
  height: 100vh;
  min-height: -webkit-fill-available;
}

.inner-padding {
  padding: 0px 35px;
}

a.link-style{
  color: inherit;
  text-decoration: none;
  font-size: 1.75rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

a.outside-links {
  color: inherit;
  text-decoration: none;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:visited {
    color: inherit;
    text-decoration: none;
  }
}

hr {
  color: #f2f2f2;
}

.side-nav {
  background-color: #f2f2f2;
}

.custom-cursor {
  cursor: pointer;
}

.top-nav {
  position: fixed !important;
  top: 0;
  z-index: 1;
}

.fixed-nav {
  position: fixed !important;
  top: 0;
  height: 100vh;
}

.main-overview {
  left: 17%;
  top: 0;
  height: 100vh;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 525px;  /* change this to whatever you want */
  width: auto;
}

.folder-scroll {
  overflow: scroll;
  height: 55%;
}

.folder-scroll-overview {
  overflow: scroll;
  height: 70%
}

@media only screen and (max-width: 768px) {
  .inner-padding {
    padding: 0px 5px;
  }
  .carousel .slide img {
    max-height: 400px;  /* change this to whatever you want */
    width: auto;
  }
}

@media only screen and (max-width: 415px) {
  .inner-padding {
    padding: 0px 5px;
  }

  .main-mobile {
    left: 17%;
    top: 0;
  }

  a.link-style {
    color: inherit;
    text-decoration: none;
    font-size: 1rem;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  .carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
  }
  
  .carousel .slide img {
    max-height: 170px;  /* change this to whatever you want */
    width: auto;
  }

  .folder-scroll {
    overflow: scroll;
    height: 40%;
  }

  .folder-scroll-overview {
    overflow: scroll;
    height: 58%;
  }

  .overview-text {
    font-size: 12px;
  }
    
}
